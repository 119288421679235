<template>
  <div class="jobauth">
    <mt-header :title="$t('jobauth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="2" />
    <div class="content">
      <mt-field
        :label="$t('companyName')"
        :placeholder="$t('companyNamePlaceholder')"
        :state="companyState"
        v-model="form.company"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P02_C03_I_COMPANYNAME',
          newValue: form.company
        })"
      ></mt-field>
      <mt-field
        :label="$t('workPhone')"
        :placeholder="$t('workPhonePlaceholder')"
        type="tel"
        v-mask="'#### #### #### #'"
        v-model="form.maskedCompanyPhone"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P02_C05_I_COMPANYPHONE',
          newValue: form.maskedCompanyPhone
        })"
      ></mt-field>
      <mt-field
        :label="$t('averageMonthlyIncome')"
        :placeholder="$t('averageMonthlyIncomePlaceholder')"
        readonly
        :state="monthlyIncomeState"
        v-model="(Enum.monthlyIncome.filter(e => e.code == form.monthlyIncome)[0]||{}).name"
        @click.native="onPopupPicker(Enum.monthlyIncome, (e) => {
          form.monthlyIncome = e.code;
          BEHAVIOR_ADD({
            id: 'P02_C02_I_MONTHLYINCOME',
            newValue: form.monthlyIncome
          })
        })"
      >
        <mt-spinner v-if="Enum.monthlyIncomeLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('addressSelect1')"
        :placeholder="$t('companyAddressSelectPlaceholder')"
        readonly
        :state="companyAddrProvinceCodeState"
        v-model="(Enum.prov.filter(e => e.code == form.companyAddrProvinceCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.prov, (e) => {
          form.companyAddrProvinceCode = e.code;
          BEHAVIOR_ADD({
            id: 'P02_C06_I_STATE',
            newValue: form.companyAddrProvinceCode
          })
        })"
      >
        <mt-spinner v-if="Enum.provLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('addressSelect2')"
        :placeholder="$t('companyAddressSelectPlaceholder')"
        readonly
        :state="companyAddrCityCodeState"
        v-model="(Enum.city.filter(e => e.code == form.companyAddrCityCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.city, (e) => {
          form.companyAddrCityCode = e.code;
          BEHAVIOR_ADD({
            id: 'P02_C07_I_CITY',
            newValue: form.companyAddrCityCode
          })
        })"
      >
        <mt-spinner v-if="Enum.cityLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('companyAddress')"
        :placeholder="$t('companyAddressPlaceholder')"
        :state="companyAddrDetailState"
        v-model="form.companyAddrDetail"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P02_C08_I_COMPANYADDRESS',
          newValue: form.companyAddrDetail
        })"
      ></mt-field>
      <mt-field
        :label="$t('salaryType')"
        :placeholder="$t('salaryTypePlaceholder')"
        readonly
        :state="salaryTypeState"
        v-model="(Enum.salaryType.filter(e => e.code == form.salaryType)[0]||{}).name"
        @click.native="onPopupPicker(Enum.salaryType, (e) => {
          form.salaryType = e.code;
          BEHAVIOR_ADD({
            id: 'P02_C10_I_SALARYTYPE',
            newValue: form.salaryType
          })
        })"
      >
        <mt-spinner v-if="Enum.salaryTypeLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <template v-if="form.salaryType && form.salaryType != 'DAILY'">
        <mt-field
          :label="$t('payDate')"
          :placeholder="$t('payDatePlaceholder')"
          readonly
          :state="payDateState"
          v-model="(Enum.payDate.filter(e => e.code == form.payDate)[0]||{}).name"
          @click.native="onPopupPicker(Enum.payDate, (e) => {
            form.payDate = e.code;
            BEHAVIOR_ADD({
              id: 'P02_C09_I_PAYDATE',
              newValue: form.payDate
            })
          })"
        >
          <mt-spinner v-if="Enum.payDateLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
        <div class="tips">{{ $t('payDateTips') }}</div>
      </template>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules } from "../../utils/rules";
import { step } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
export default {
  name: 'Jobauth',
  mixins: [mixin],
  components: {
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      form: {
        company: '', // 公司名称
        maskedCompanyPhone: '', // 工作电话
        monthlyIncome: '', // 平均月收入
        companyAddrProvinceCode: '', // 省市
        companyAddrCityCode: '', // 区
        companyAddrDetail: '', // 房屋号
        salaryType: '', // 工资类型
        payDate: '', // 发薪周期
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    companyState() {
      this.onStorageItem('company')
      return this.isCheck ? emptyRules(this.form.company) : null;
    },
    monthlyIncomeState() {
      this.onStorageItem('monthlyIncome')
      return this.isCheck ? emptyRules(this.form.monthlyIncome) : null;
    },
    companyAddrProvinceCodeState() {
      this.onStorageItem('companyAddrProvinceCode')
      return this.isCheck ? emptyRules(this.form.companyAddrProvinceCode) : null;
    },
    companyAddrCityCodeState() {
      this.onStorageItem('companyAddrCityCode')
      return this.isCheck ? emptyRules(this.form.companyAddrCityCode) : null;
    },
    companyAddrDetailState() {
      this.onStorageItem('companyAddrDetail')
      return this.isCheck ? emptyRules(this.form.companyAddrDetail) : null;
    },
    salaryTypeState() {
      this.onStorageItem('salaryType')
      return this.isCheck ? emptyRules(this.form.salaryType) : null
    },
    payDateState() {
      this.onStorageItem('payDate')
      return this.isCheck ? emptyRules(this.form.payDate) : null
    }
  },
  watch: {
    'form.maskedCompanyPhone': {
      handler() {
        this.onStorageItem('maskedCompanyPhone')
      }
    },
    'form.salaryType': {
      handler(val, oldVal) {
        if (val && val != 'DAILY') {
          this.GET_PAY_DATE(val); // 获取工资周期
          if (oldVal) {
            this.form.payDate = '';
          }
        } else {
          this.form.payDate = '';
        }
      },
    },
    // 监听省市发生变化重置区内容
    'form.companyAddrProvinceCode': {
      handler(val) {
        if (!val) return
        this.GET_DISTRICT_CITY(val).then(() => {
          let length = this.Enum.city.filter(e => e.code == this.form.companyAddrCityCode).length
          if (!length) {
            this.form.companyAddrCityCode = ''
          }
        })
      },
      immediate: true
    },
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      if (this.apply[val]) {
        this.form[val] = this.apply[val]
      }
    })
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P02_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P02_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_MONTHLY_INCOME(); // 获取平均月收入
      this.GET_DISTRICT_PROV(); // 获取省市
      this.GET_SALARY_TYPE(); // 获取工资类型
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('company') >= 0 && this.companyState != "success") {
        return this.companyState == 'warning'? this.$t('companyNamePlaceholder'): this.$t('companyNameError')
      }
      if (list.indexOf('monthlyIncome') >= 0 && this.monthlyIncomeState != "success") {
        return this.$t('averageMonthlyIncomeError')
      }
      if (list.indexOf('companyAddrProvinceCode') >= 0 && this.companyAddrProvinceCodeState != "success") {
        return this.$t('companyAddressSelectPlaceholder')
      }
      if (list.indexOf('companyAddrCityCode') >= 0 && this.companyAddrCityCodeState != "success") {
        return this.$t('companyAddressSelectPlaceholder')
      }
      if (list.indexOf('companyAddrDetail') >= 0 && this.companyAddrDetailState != "success") {
        return this.companyAddrDetailState == 'warning'? this.$t('companyAddressPlaceholder'): this.$t('companyAddressError')
      }
      if (list.indexOf('salaryType') >= 0 && this.salaryTypeState != "success") {
        return this.$t('salaryTypeError')
      }
      if (list.indexOf('payDate') >= 0 && this.payDateState != "success") {
        return this.$t('payDateError')
      }
    },
    // 提交下一步
    async submit() {
      this.BEHAVIOR_ADD({
        id: 'P02_C98_B_SUBMIT'
      })
      this.isCheck = true;
      let list = [
        'company',
        'monthlyIncome',
        'companyAddrProvinceCode',
        'companyAddrCityCode',
        'companyAddrDetail',
        'salaryType',
      ]
      if (this.form.salaryType && this.form.salaryType != 'DAILY') {
        list.push('payDate')
      }
      var error = this.validation(list);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('03_JOB_AUTH', { content_name: '03_JOB_AUTH' })
        }
        // Google Analytics  JOB_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'JOB_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        authName: "JOB_AUTH",
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),

        companyAddrProvince: (this.Enum.prov.filter(e => e.code == this.form.companyAddrProvinceCode)[0]||{}).name,
        companyAddrCity: (this.Enum.city.filter(e => e.code == this.form.companyAddrCityCode)[0]||{}).name,

        applyId: this.user.applyId,
        ...this.form,
        companyPhone: this.form.maskedCompanyPhone.replace(/\s/g, ''),
        currentStep: 3,
        totalSteps: 7,
      }
      await this.$axios({
        method: "post",
        url: step,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/relationshipauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('03_JOB_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.jobauth {
  .bottom {
    padding: 40px 20px 20px 20px;
  }
  .tips {
    font-size: 12px;
    color: #C9C9C9;
    margin: 0 10px 0px 105px;
  }
}
</style>