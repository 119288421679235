var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobauth"},[_c('mt-header',{attrs:{"title":_vm.$t('jobauth')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{attrs:{"icon":"back"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v(_vm._s(_vm.$t('back')))])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":2}}),_c('div',{staticClass:"content"},[_c('mt-field',{attrs:{"label":_vm.$t('companyName'),"placeholder":_vm.$t('companyNamePlaceholder'),"state":_vm.companyState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
        id: 'P02_C03_I_COMPANYNAME',
        newValue: _vm.form.company
      })}},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### #'),expression:"'#### #### #### #'"}],attrs:{"label":_vm.$t('workPhone'),"placeholder":_vm.$t('workPhonePlaceholder'),"type":"tel"},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
        id: 'P02_C05_I_COMPANYPHONE',
        newValue: _vm.form.maskedCompanyPhone
      })}},model:{value:(_vm.form.maskedCompanyPhone),callback:function ($$v) {_vm.$set(_vm.form, "maskedCompanyPhone", $$v)},expression:"form.maskedCompanyPhone"}}),_c('mt-field',{attrs:{"label":_vm.$t('averageMonthlyIncome'),"placeholder":_vm.$t('averageMonthlyIncomePlaceholder'),"readonly":"","state":_vm.monthlyIncomeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.monthlyIncome, (e) => {
        _vm.form.monthlyIncome = e.code;
        _vm.BEHAVIOR_ADD({
          id: 'P02_C02_I_MONTHLYINCOME',
          newValue: _vm.form.monthlyIncome
        })
      })}},model:{value:((_vm.Enum.monthlyIncome.filter(e => e.code == _vm.form.monthlyIncome)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.monthlyIncome.filter(e => e.code == _vm.form.monthlyIncome)[0]||{}), "name", $$v)},expression:"(Enum.monthlyIncome.filter(e => e.code == form.monthlyIncome)[0]||{}).name"}},[(_vm.Enum.monthlyIncomeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1),_c('mt-field',{attrs:{"label":_vm.$t('addressSelect1'),"placeholder":_vm.$t('companyAddressSelectPlaceholder'),"readonly":"","state":_vm.companyAddrProvinceCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.prov, (e) => {
        _vm.form.companyAddrProvinceCode = e.code;
        _vm.BEHAVIOR_ADD({
          id: 'P02_C06_I_STATE',
          newValue: _vm.form.companyAddrProvinceCode
        })
      })}},model:{value:((_vm.Enum.prov.filter(e => e.code == _vm.form.companyAddrProvinceCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.prov.filter(e => e.code == _vm.form.companyAddrProvinceCode)[0]||{}), "name", $$v)},expression:"(Enum.prov.filter(e => e.code == form.companyAddrProvinceCode)[0]||{}).name"}},[(_vm.Enum.provLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1),_c('mt-field',{attrs:{"label":_vm.$t('addressSelect2'),"placeholder":_vm.$t('companyAddressSelectPlaceholder'),"readonly":"","state":_vm.companyAddrCityCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.city, (e) => {
        _vm.form.companyAddrCityCode = e.code;
        _vm.BEHAVIOR_ADD({
          id: 'P02_C07_I_CITY',
          newValue: _vm.form.companyAddrCityCode
        })
      })}},model:{value:((_vm.Enum.city.filter(e => e.code == _vm.form.companyAddrCityCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.city.filter(e => e.code == _vm.form.companyAddrCityCode)[0]||{}), "name", $$v)},expression:"(Enum.city.filter(e => e.code == form.companyAddrCityCode)[0]||{}).name"}},[(_vm.Enum.cityLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1),_c('mt-field',{attrs:{"label":_vm.$t('companyAddress'),"placeholder":_vm.$t('companyAddressPlaceholder'),"state":_vm.companyAddrDetailState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
        id: 'P02_C08_I_COMPANYADDRESS',
        newValue: _vm.form.companyAddrDetail
      })}},model:{value:(_vm.form.companyAddrDetail),callback:function ($$v) {_vm.$set(_vm.form, "companyAddrDetail", $$v)},expression:"form.companyAddrDetail"}}),_c('mt-field',{attrs:{"label":_vm.$t('salaryType'),"placeholder":_vm.$t('salaryTypePlaceholder'),"readonly":"","state":_vm.salaryTypeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.salaryType, (e) => {
        _vm.form.salaryType = e.code;
        _vm.BEHAVIOR_ADD({
          id: 'P02_C10_I_SALARYTYPE',
          newValue: _vm.form.salaryType
        })
      })}},model:{value:((_vm.Enum.salaryType.filter(e => e.code == _vm.form.salaryType)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.salaryType.filter(e => e.code == _vm.form.salaryType)[0]||{}), "name", $$v)},expression:"(Enum.salaryType.filter(e => e.code == form.salaryType)[0]||{}).name"}},[(_vm.Enum.salaryTypeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1),(_vm.form.salaryType && _vm.form.salaryType != 'DAILY')?[_c('mt-field',{attrs:{"label":_vm.$t('payDate'),"placeholder":_vm.$t('payDatePlaceholder'),"readonly":"","state":_vm.payDateState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.payDate, (e) => {
          _vm.form.payDate = e.code;
          _vm.BEHAVIOR_ADD({
            id: 'P02_C09_I_PAYDATE',
            newValue: _vm.form.payDate
          })
        })}},model:{value:((_vm.Enum.payDate.filter(e => e.code == _vm.form.payDate)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.payDate.filter(e => e.code == _vm.form.payDate)[0]||{}), "name", $$v)},expression:"(Enum.payDate.filter(e => e.code == form.payDate)[0]||{}).name"}},[(_vm.Enum.payDateLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#820ECA","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('payDateTips')))])]:_vm._e()],2),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }